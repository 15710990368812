





















































import { Component, Vue, Prop, Ref } from "vue-property-decorator";

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxPosition,
  DxGroupPanel,
  DxSearchPanel,
  DxHeaderFilter,
  DxFormItem,
  DxGrouping,
  DxFilterRow,
  DxPager,
  DxSummary,
  DxTotalItem,
  DxScrolling
} from "devextreme-vue/data-grid";

import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { UtilsString } from "@/utils/utils-string";
@Component({
  components: {
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
    DxPosition,
    DxGroupPanel,
    DxSearchPanel,
    DxHeaderFilter,
    DxFormItem,
    DxGrouping,
    DxFilterRow,
    DxSummary,
    DxTotalItem,
    DxScrolling
  }
})
export default class DataTableFilterAlimentos extends Vue {
  @Prop({
    default: "id",
    type: String,
    required: false
  })
  identificador!: string;
  @Prop({
    default: 5,
    type: Number,
    required: false
  })
  pagesize!: string;
  @Prop({
    default: true,
    type: Boolean,
    required: false
  })
  HighlightCaseSensitive!: boolean;

  @Prop({
    required: true
  })
  DataSource!: any;

  @Prop({
    required: true
  })
  Columns!: ClassColumnDataTable[];

  @Ref("filter_Alimentos") VisualDxDataGrid!: DxDataGrid;

  public focusedRowKey: number = 0;
  public focusedRowIndex: number = 0;
  public get editButtons() {
    let btns = [];
    btns.push({
      hint: "añadir a la dieta",
      icon: "add",
      visible: true,
      onClick: () => this.añadir()
    });

    return btns;
  }

  public añadir() {
    this.$emit("add", this.RowIndex());
  }

  public onRowDblClick(e: any) {
    this.añadir();
  }

  public RowKey(): string {
    let dtg: any = this.DxDataGrid;
    let rowKey = dtg.focusedRowKey;
    return UtilsString.ValueOfValue(dtg.instance.getKeyByRowIndex(rowKey));
  }

  public RowIndex(): string {
    let dtg: any = this.DxDataGrid;
    let rowIndex = dtg.focusedRowIndex;
    return UtilsString.ValueOfValue(dtg.instance.getKeyByRowIndex(rowIndex));
  }
  public DataForRow(): any[] {
    let dtg: any = this.DxDataGrid;
    return dtg.instance.getRowElement(this.RowIndex());
  }
  public get displayFormatSummary() {
    return "núm. registros {0}";
  }
  public get DxDataGrid() {
    return this.VisualDxDataGrid;
  }
  public get pageSizes() {
    return [5, 10, 20, 40, 60, 80, 100];
  }

  public get ShowSumaryIn() {
    for (let i = 0; i < this.Columns.length; i++) {
      if (this.Columns[i].visible) {
        return this.Columns[i].datafield;
      }
    }
  }
}
